<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="mx-auto affiliation-card"
      rounded="lg"
      max-width="344"
      :elevation="hover ? '4' : '0'"
    >
      <v-img
        v-if="affiliation.banner && affiliation.banner != ''"
        :src="affiliation.banner"
        :gradient="
          hover
            ? 'to top right, rgba(255,255,255,.33), rgba(196, 196, 196,.7)'
            : ''
        "
        :height="150"
        @click="editAffiliation()"
      >
      </v-img>
      <v-img
        v-else
        src="@/assets/images/shared/withoutbanner.png"
        :gradient="
          hover
            ? 'to top right, rgba(255,255,255,.33), rgba(196, 196, 196,.7)'
            : ''
        "
        :height="150"
        @click="editAffiliation()"
      >
      </v-img>

      <v-card-title
        class="d-inline-block text-truncate pt-1 text-primary-variant"
        style="max-width: 100%"
        @click="editAffiliation()"
      >
        <span class="wc-body-2 font-weight-black">{{ affiliation.name }}</span>
      </v-card-title>

      <v-card-subtitle>
        <v-row dense align="center">
          <v-col cols="6" @click="editAffiliation()">
            <v-chip
              small
              label
              :color="affiliation.published ? 'blue lighten-3' : ''"
            >
              <span
                class="wc-caption-2 text-primary-variant font-weight-bold text-uppercase"
              >
                {{ affiliation.published ? "Publicada" : "No publicada" }}
              </span>
            </v-chip>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-tooltip top v-if="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="color.primaryVariant"
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialogToDeleteAffiliation(affiliation._id)"
                  :disabled="!user.permissions.Membership.delete"
                >
                  <v-icon size="18"> far fa-trash-alt </v-icon>
                </v-btn>
              </template>
              <span>Eliminar membresía</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="color.primaryVariant"
                  small
                  x-small
                  icon
                  v-bind="attrs"
                  v-on="on"
                  v-clipboard:copy="shareItem(affiliation._id)"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <v-icon size="18"> fas fa-link </v-icon>
                </v-btn>
              </template>
              <span>Compartir URL de membresía</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-card>
  </v-hover>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { colors } from "@/constants/colors.js";
export default {
  props: {
    affiliation: { type: Object, required: true },
  },
  data() {
    return {
      affiliationSelectedId: null,
      color: colors,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    ...mapMutations("affiliation", ["setAffiliationSelected"]),
    editAffiliation() {
      this.setAffiliationSelected(this.affiliation);
      this.$router.push(`/mi-membresia/micrositio/${this.affiliation._id}`);
    },
    openDialogToDeleteAffiliation(affiliationSelectedId) {
      this.$emit("openDialogToDeleteAffiliation", affiliationSelectedId);
    },
    /**
     * Función que devuelve un link para compartir la membresía seleccionada.
     * @param { string } id Identificador único de una membresía.
     */
    shareItem(id) {
      return `${window.location.origin}/micrositio-membership/${id}`;
    },
    onCopy: function (e) {
      this.show({
        text: "¡Se ha copiado la url de la membresía al portapapeles!",
        color: "primary",
      });
    },
    onError: function (e) {
      this.show({
        text: "¡Ha ocurrido un problema al copiar la url de la membresía!",
        color: "error",
      });
    },
  },
};
</script>
<style scoped>
.affiliation-card {
  transition: all 0.8s;
}

.affiliation-card:hover {
  transform: scale(1.05);
}
</style>