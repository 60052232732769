var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('Chat'),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"10","sm":"8"}},[_c('v-sheet',{attrs:{"color":"accent rounded-xl pl-3"}},[_c('v-text-field',{staticClass:"rounded-search",attrs:{"solo":"","dense":"","placeholder":"Buscar membresía","hide-details":"auto","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchAffiliations()},"click:clear":function($event){(_vm.affiliationsSearchData.name = ''), _vm.searchAffiliations()}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',[_vm._v("fa fa-search")])],1)]}}])},[_vm._v(" Clic para buscar membresía ")])]},proxy:true}]),model:{value:(_vm.affiliationsSearchData.name),callback:function ($$v) {_vm.$set(_vm.affiliationsSearchData, "name", $$v)},expression:"affiliationsSearchData.name"}})],1)],1),(
        this.boolUserPermission === false && 
        _vm.organizationAffiliationsPaginated.totalDocs == 0
      )?_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"2","sm":"4"}},[_c('v-btn',{class:_vm.$vuetify.breakpoint.smAndUp ? 'rounded-xl' : '',attrs:{"to":"/nueva-membresia","color":"accent","small":_vm.$vuetify.breakpoint.xsOnly,"fab":_vm.$vuetify.breakpoint.smAndDown,"disabled":!_vm.user.permissions.Membership.create}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-icon',[_vm._v(" mdi-plus ")]):_c('span',{staticClass:"font-weight-black black--text"},[_vm._v("Nueva membresía")])],1)],1):_c('v-col')],1),_c('v-row',{class:_vm.$vuetify.breakpoint.smAndUp ? 'mx-5' : 'mx-1'},[_c('v-col',{attrs:{"cols":"12"}},[_c('affiliation-card-list',{attrs:{"items":_vm.organizationAffiliationsPaginated.affiliations},on:{"fetchAffiliationsAfterDelete":_vm.fetchAffiliationsAfterDelete}})],1),(
        _vm.organizationAffiliationsPaginated.totalDocs == 0 &&
        !_vm.organizationAffiliationsPaginated.hasPrevPage
      )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"headline text-center font-weight-bold"},[_vm._v(" No se encontraron resultados ")])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-img',{attrs:{"src":require("@/assets/images/general/no_data_events.svg"),"max-width":"300"}})],1)],1):_vm._e()],1),(_vm.loadingAffiliations)?_c('v-row',{staticClass:"mb-15"},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"title font-weight-bold mb-0 text-center"},[_vm._v(" Cargando membresías ")])]),_c('v-col',{attrs:{"cols":"12","offset-sm":"3","sm":"6"}},[_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":"","rounded":"","height":"6"}})],1)],1):_vm._e(),(!_vm.loaderVisible)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.fetchAffiliations),expression:"fetchAffiliations"}]})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }