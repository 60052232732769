<!--Vista que muestra la lista de membresías del organizador-->
<template>
  <v-container fluid>
    <Chat></Chat>

    <v-row class="ma-0">
      <v-col cols="10" sm="8">
        <v-sheet color="accent rounded-xl pl-3">
          <v-text-field
            v-model="affiliationsSearchData.name"
            solo
            dense
            placeholder="Buscar membresía"
            hide-details="auto"
            class="rounded-search"
            clearable
            v-on:keyup.enter="searchAffiliations()"
            @click:clear="
              (affiliationsSearchData.name = ''), searchAffiliations()
            "
          >
            <template v-slot:prepend>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on">
                    <v-icon>fa fa-search</v-icon>
                  </v-btn>
                </template>
                Clic para buscar membresía
              </v-tooltip>
            </template>
          </v-text-field>
        </v-sheet>
      </v-col>
      <v-col
        cols="2"
        sm="4"
        class="d-flex justify-end"
        v-if="
          this.boolUserPermission === false && 
          organizationAffiliationsPaginated.totalDocs == 0
        "
      >
        <v-btn
          to="/nueva-membresia"
          color="accent"
          :small="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.smAndUp ? 'rounded-xl' : ''"
          :fab="$vuetify.breakpoint.smAndDown"
          :disabled="!user.permissions.Membership.create"
        >
          <v-icon v-if="$vuetify.breakpoint.smAndDown"> mdi-plus </v-icon>
          <span v-else class="font-weight-black black--text"
            >Nueva membresía</span
          >
        </v-btn>
      </v-col>

      <v-col v-else> </v-col>
    </v-row>
    <v-row :class="$vuetify.breakpoint.smAndUp ? 'mx-5' : 'mx-1'">
      <v-col cols="12">
        <affiliation-card-list
          :items="organizationAffiliationsPaginated.affiliations"
          @fetchAffiliationsAfterDelete="fetchAffiliationsAfterDelete"
        ></affiliation-card-list>
      </v-col>
      <v-col
        v-if="
          organizationAffiliationsPaginated.totalDocs == 0 &&
          !organizationAffiliationsPaginated.hasPrevPage
        "
        cols="12"
      >
        <v-row justify="center">
          <v-col cols="12">
            <p class="headline text-center font-weight-bold">
              No se encontraron resultados
            </p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-img
            src="@/assets/images/general/no_data_events.svg"
            max-width="300"
          ></v-img>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="loadingAffiliations" class="mb-15">
      <v-col cols="12">
        <p class="title font-weight-bold mb-0 text-center">
          Cargando membresías
        </p>
      </v-col>
      <v-col cols="12" offset-sm="3" sm="6">
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <!--Si ya no se están cargando las membresías habilitar el páginador mediante el scroll-->
    <v-row v-if="!loaderVisible">
      <v-col cols="12">
        <v-card v-intersect="fetchAffiliations"></v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//import AffiliationCard from "@/components/shared/AffiliationCard";
//import AffiliationCard from "@/components/shared/EventCard";
import Chat from "@/components/shared/Chat.vue";

import AffiliationCardList from "@/components/dashboard/affiliation/AffiliationCardList";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  components: {
    AffiliationCardList,
    Chat,
  },
  computed: {
    ...mapState("affiliation", [
      "organizationAffiliations",
      "organizationAffiliationsPaginated",
    ]),
    ...mapState("loader", ["loaderVisible"]),
    ...mapState("user", ["user"]),
  },
  data() {
    return {
      loadingAffiliations: false,
      affiliationsSearchData: {
        name: "",
        page: 0,
        perPage: 12,
      },
      boolUserPermission: false,
    };
  },
  methods: {
    ...mapActions("affiliation", [
      "fetchOrganizationAffiliations",
      "fetchOrganizationAffiliationsPaginated",
    ]),
    ...mapMutations("affiliation", ["clearOrganizationAffiliationsPaginated"]),
    ...mapMutations("user", ["logout"]),
    ...mapMutations("notification", ["show"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    async fetchAffiliations() {
      
      if (this.organizationAffiliationsPaginated.hasNextPage == true) {
        this.loadingAffiliations = true;
        await setTimeout(async () => {
          this.affiliationsSearchData.page++;
          let response = await this.fetchOrganizationAffiliationsPaginated(
            this.affiliationsSearchData
          );
          this.loadingAffiliations = false;
        }, 1500);
      } else {
        //console.log("Ya no hay más membresías");
      }
    },
    async searchAffiliations() {
      this.loadingAffiliations = true;
      this.clearOrganizationAffiliationsPaginated();
      this.affiliationsSearchData.page = 1;
      await this.fetchOrganizationAffiliationsPaginated(
        this.affiliationsSearchData
      );
      this.loadingAffiliations = false;
    },
    async fetchAffiliationsAfterDelete() {
      await this.searchAffiliations();
    },

    isValid(permission) {
      return permission.action === "create" && permission.subject === "Membership";
    },

  },
  async mounted() {
  },
};
</script>
<style scoped>
/* html,
body,
.theme--light.v-application,
.v-application {
    background-color: #f9fafb !important;
} */
.rounded-search {
  border-radius: 0px 24px 24px 0px !important;
}
.affiliation-container {
  min-height: 93vh;
}
</style>