<template>
  <v-row :class="$vuetify.breakpoint.mdAndUp?'mb-4':'mb-10'" :justify="$vuetify.breakpoint.smAndDown?'center':'start'">
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="3"
      v-for="item in items"
      :key="item.id"
      class="mb-5"
    >
      <affiliation-card :affiliation="item" @openDialogToDeleteAffiliation="openDialogToDeleteAffiliation"></affiliation-card>
    </v-col>
    <!-- INICIO Dialog para confirmar la eliminación de una membresía -->
    <v-dialog v-model="deleteAffiliationDialog" width="600">
      <v-card>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="10" md="7" class="mt-8 d-flex justify-center">
              <v-img class="" src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg" max-width="220" height="auto" contain></v-img>
            </v-col>
          </v-row>
          <v-row>
          <v-col cols="12" class="mt-2">
            <p class="text-center font-weight-bold headline mb-0">
              Eliminar membresía
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="title text-center mb-0">¿Está seguro que quiere eliminar la membresía seleccionada?</p>
            <p class="title text-center">Los datos de este registro no podrán ser recuperados.</p>
          </v-col>
        </v-row>
        <v-row
          class="d-flex pb-2 mt-5"
          :class="
            $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
          "
        >
          <v-btn class="font-weight-bold black--text mx-2" color="grey lighten-3" rounded elevation="0" @click="deleteAffiliationDialog = false">
            Cancelar
          </v-btn>
          <v-btn class="font-weight-bold black--text mx-2" color="accent" rounded elevation="0" @click="$_deleteAffiliation()">
            Aceptar
          </v-btn>
        </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- FIN Dialog para confirmar la eliminación de una membresía -->
  </v-row>
</template>

<script>
import AffiliationCard from "./AffiliationCard";
import { mapMutations, mapActions } from "vuex";

export default {
  components: {
    AffiliationCard,
  },
  props: {
    items: { type: Array, required: true },
  },
  data() {
    return {
      affiliationSelectedId: null,
      deleteAffiliationDialog: false
    }
  },
  methods: {
    ...mapActions("affiliation", ["deleteAffiliation"]),
    ...mapMutations("notification", ["show"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    async $_deleteAffiliation() {
      this.loading();
      let response = await this.deleteAffiliation(this.affiliationSelectedId);
      this.show({
        text: response.message,
        color: response.status?"primary":"error"
      });
      this.deleteAffiliationDialog = false;
      this.$emit('fetchAffiliationsAfterDelete');
      this.loaded();
    },
    openDialogToDeleteAffiliation(affiliationSelectedId) {
      this.affiliationSelectedId = affiliationSelectedId;
      this.deleteAffiliationDialog = true;
      //this.affiliationSelectedId = affiliationSelectedId;
    },
  },
}
</script>